@media only screen and (max-width: 1920px){
     .ribbon h6 {
        width: 220%;
        left: -30%;
        top: -35%;
    }
}
@media only screen and (max-width: 1000px) {
    .ribbon{
        visibility: hidden;
    }
}
.ribbon{
    display: block;
    position: fixed;
    top: 0;
    right:0;
    width:25vh;
    height: 200px;
    z-index:6;
    font-size: minmax(18px, 1.25vh);
    line-height: 60px;
}

.ribbon h6 {
    padding: 10px 0;
    transform: translate3d(0,0,0) rotate(45deg);
    background: linear-gradient(#94bbe9 0%, #2989d8 100%);
    position: absolute;
    color: #fff;
    display: block;
    z-index: 5;
    transform-origin: 0 0;
    text-align: center;
    cursor: pointer;
}