@import url(https://fonts.googleapis.com/css?family=Noto+Sans&display=swap);

body{
  font-family: 'Noto Sans', sans-serif;
  margin: 0;
}
.grid{
  display: grid;

  
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 50vh;
}

@media screen and (max-width: 1000px) {
  .grid{
      grid-template-columns: repeat(1, 1fr);
  }
}

.homepage-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #353535;
  font-size: 3rem;
  color: #fff;
  height: 100%;
  width: 100%;
  -webkit-transition: all 500ms;
  transition: all 500ms;
  overflow: hidden;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

Link{
  text-decoration: none;
}

.homepage-card .img-desc{
  visibility: hidden;
  opacity: 0;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.5);
  text-align: center;
}

.img-desc h4 {
  font-size: 2rem;
  position: relative;
  top: 5em;
}

.img-desc h5 {
  font-size: 1rem;
  position: relative;
  top: 40%;
  left: 25%;
  width: 50%;
}

@media screen and (min-width: 1001px) {
    

  .img-wrap:hover .img-desc{
      -webkit-transition-duration: 500ms;
              transition-duration: 500ms;
      visibility: visible;
      opacity: 1;
      
      /* transition effect*/
      -webkit-transform: translate(-3px) scale(1.5);
              transform: translate(-3px) scale(1.5);
  }
}

@media screen and (max-width: 1000px) {
   .homepage-card .img-desc{
      visibility: visible;
      opacity: 1;
   }
}


@media only screen and (max-width: 1920px){
     .ribbon h6 {
        width: 220%;
        left: -30%;
        top: -35%;
    }
}
@media only screen and (max-width: 1000px) {
    .ribbon{
        visibility: hidden;
    }
}
.ribbon{
    display: block;
    position: fixed;
    top: 0;
    right:0;
    width:25vh;
    height: 200px;
    z-index:6;
    font-size: minmax(18px, 1.25vh);
    line-height: 60px;
}

.ribbon h6 {
    padding: 10px 0;
    -webkit-transform: translate3d(0,0,0) rotate(45deg);
            transform: translate3d(0,0,0) rotate(45deg);
    background: -webkit-gradient(linear, left top, left bottom, from(#94bbe9), to(#2989d8));
    background: linear-gradient(#94bbe9 0%, #2989d8 100%);
    position: absolute;
    color: #fff;
    display: block;
    z-index: 5;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    text-align: center;
    cursor: pointer;
}
body{
    background:rgb(255, 184, 140);
}
.picCol{
    height: auto;  
    background-color: #ffb88c;
}

.aboutMePic{
    width: 50%;
    border-radius: 100%;
    position: relative;
    margin: 25% 0px;
    left: 25%;
}
.aboutMeText{
    background-color: rgb(255, 184, 140);
    text-align: center;
    margin-top: 3px;
}

.aboutMeDesc{
    margin: 10% 0;
}

