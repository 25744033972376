body{
    background:rgb(255, 184, 140);
}
.picCol{
    height: auto;  
    background-color: #ffb88c;
}

.aboutMePic{
    width: 50%;
    border-radius: 100%;
    position: relative;
    margin: 25% 0px;
    left: 25%;
}
.aboutMeText{
    background-color: rgb(255, 184, 140);
    text-align: center;
    margin-top: 3px;
}

.aboutMeDesc{
    margin: 10% 0;
}
