
@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');

body{
  font-family: 'Noto Sans', sans-serif;
  margin: 0;
}
.grid{
  display: grid;

  
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 50vh;
}

@media screen and (max-width: 1000px) {
  .grid{
      grid-template-columns: repeat(1, 1fr);
  }
}

.homepage-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #353535;
  font-size: 3rem;
  color: #fff;
  height: 100%;
  width: 100%;
  transition: all 500ms;
  overflow: hidden;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

Link{
  text-decoration: none;
}

.homepage-card .img-desc{
  visibility: hidden;
  opacity: 0;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.5);
  text-align: center;
}

.img-desc h4 {
  font-size: 2rem;
  position: relative;
  top: 5em;
}

.img-desc h5 {
  font-size: 1rem;
  position: relative;
  top: 40%;
  left: 25%;
  width: 50%;
}

@media screen and (min-width: 1001px) {
    

  .img-wrap:hover .img-desc{
      transition-duration: 500ms;
      visibility: visible;
      opacity: 1;
      
      /* transition effect*/
      transform: translate(-3px) scale(1.5);
  }
}

@media screen and (max-width: 1000px) {
   .homepage-card .img-desc{
      visibility: visible;
      opacity: 1;
   }
}

